import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostsList from "../components/blog/posts-list";

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

const IndexPage = props => {
  const posts = props.data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
      <BlogWrapper>
        <PostsList posts={posts} />
      </BlogWrapper>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query PostsList {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
            image {
              childImageSharp {
                resize(width: 560, height: 315) {
                  src
                }
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
